/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { CoopPartner } from '@/components/pages/cooperation/partners/cooperation-partners.interface'
import { GuestRequest } from '@/components/pages/become-guest/request/become-guest-request.interface'
const namespace: string = 'customer';

export default class BecomeGuestRequestPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('guestRequest', {namespace}) guestRequest: any;

	guest: GuestRequest = {
		name: "",
		email: "",
		phone: "",
		description: ""
	}

	onSubmit() {
		this.guestRequest(this.guest);
		this.guest = {
			name: "",
			email: "",
			phone: "",
			description: ""
		}
	}
}
